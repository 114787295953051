import jwtDecode from 'jwt-decode';
import http from './http';
import socket from './socket';

const tokenKey = 'token';

async function login(data) {
    const { data: response } = await http.post('/auth', data);

    if (response.jwt) {
        sessionStorage.setItem(tokenKey, response.jwt);

        http.setToken(getToken());
        socket.setToken(getToken());

        return true;
    } else {
        return false;
    }
};

function loginWithToken(token) {
    sessionStorage.setItem(tokenKey, token);
    socket.setToken(getToken());
}

function logout() {
    sessionStorage.removeItem(tokenKey);
};

function getToken() {
    return sessionStorage.getItem(tokenKey);
}

function getUser() {
    try {
        const jwt = sessionStorage.getItem(tokenKey);
        
        return jwtDecode(jwt);
    } catch (e) {
        return null;
    }
}

http.setToken(getToken());
socket.setToken(getToken());

const auth = {
    login,
    loginWithToken,
    logout,
    getToken,
    getUser
};

export default auth;