import http from './http';

function saveUser(user) {
	if (user.id) {
		const data = { ...user };

		delete data.id;

		return http.put('/users/' + user.id, data);
	}

	return http.post('/users', user);
}

const user = {
	save: saveUser
};

export default user;
