import { useState } from 'react';
import Login from './login';
import Register from './register';
import useSwipe from '../hooks/swipe';
import useWindowDimensions from '../hooks/window';
import ui from '../images/login.ui.png';

const Start = ({ setHasUser }) => {
	const [view, setView] = useState('login');
	const dimensions = useWindowDimensions();
	const attributes = dimensions.size === 'narrow' ? {className: 'narrow'} : {style: {background: `url(${ui})`}};
	const swipeHandlers = {...useSwipe({left: () => setView('login')})};

	return (
		<div id="background-user-outer"{...swipeHandlers}>
			<div id="background-user"{...attributes}>
				<div id="user-container">
					{view === 'login' && <Login setHasUser={setHasUser} setView={setView} />}
					{view === 'register' && <Register setHasUser={setHasUser} setView={setView} />}
				</div>
			</div>
		</div>
	);
}

export default Start;