import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
        size: window.innerWidth >= 1325 ? 'max' : (window.innerWidth >= 1280 ? 'wide' : (window.innerWidth >= 662 ? 'medium' : 'narrow'))
    }
};

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
