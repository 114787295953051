import pako from 'pako';

const Socket = {
	socket: null,
	token: null,
	callbacks: {onopen: [], onmessage: [], onclose: [], onreconnect: null},
	setToken(token) {
		this.token = token;
	},
	connect(callbacks) {
		const useAudio = localStorage.getItem('useAudio') || 1;
		const useMusic = localStorage.getItem('useMusic') || 0;

		this.socket = new WebSocket(`${process.env.REACT_APP_WS_ENDPOINT}?auth-token=${this.token}&audio=${useAudio}&music=${useMusic}`);

		this.callbacks.onopen = callbacks.onopen || {};
		this.callbacks.onmessage.push(callbacks.onmessage || {});
		this.callbacks.onclose.push(callbacks.onclose || {});

		this.socket.onopen = event => {
			this.callbacks.onopen.call(this);

			if (this.callbacks.onreconnect) {
				this.callbacks.onreconnect.call(this);
			}
		}

		this.socket.onmessage = event => {
			try {
				const reader = new FileReader();

				reader.onload = event => {
					const inflated = pako.inflate(event.target.result);
					const dataView = new DataView(inflated.buffer.slice(inflated.byteOffset, inflated.byteLength));
					const dataLength = dataView.getUint32(0);
					let result = '';
					let position = 4;

					for (let x = 0; x < dataLength; x++) {
						result += String.fromCharCode(dataView.getUint8(position++));
					}

					const data = JSON.parse(result);

					if (process.env.NODE_ENV === 'development') {
						console.log(data);
					}

					if (position !== dataView.byteLength) {
						const length = dataView.getUint16(position);
						const audio = {};

						for (let x = 0; x < length; x++) {
							audio[dataView.getUint16(x * 10 + 2 + position)] = dataView.buffer.slice(
								dataView.getUint32(x * 10 + 4 + position) + position, 
								dataView.getUint32(x * 10 + 4 + position) + dataView.getUint32(x * 10 + 8 + position) + position
							);
						}

						data.audio = audio;
					}

					this.callbacks.onmessage.forEach(callback => {
						callback.call(this, data);
					});
				}

				reader.readAsArrayBuffer(event.data);
			} catch (err) {
				console.log(err);
			}
			/*const stream = new DecompressionStream('deflate');
			const decompressed = event.data.stream().pipeThrough(stream);
			const reader = decompressed.getReader();
			const arrays = [];
			let length = 0;

			const read = () => {
				reader.read().then(({ done, value }) => {
					if (typeof value !== 'undefined') {
						arrays.push(value);
						length += value.length;
					}

					if (! done) {
						read();

						return;
					}

					const result = new Uint8Array(length);
					let position = 0;

					arrays.forEach(array => {
						result.set(array, position);

						position += array.length;
					});

					const data = new DataView(result.buffer.slice(result.byteOffset, result.byteLength));

					this.callbacks.onmessage.forEach(callback => {
						callback.call(this, data);
					});
				});
			}

			read();*/
		}

		this.socket.onclose = event => {
			if (event.code !== 1000) {
				this.callbacks.onclose.forEach(callback => {
					callback.call(this, event);
				});
			}

			this.callbacks.onmessage = [];
			this.callbacks.onclose = [];
		}
	},
	disconnect(code = 1000) {
		if (this.socket) {
			this.socket.close(code);
		}
		
		this.socket = null;
	},
	send(data) {
		data['auth-token'] = this.token;

		if (process.env.NODE_ENV === 'development') {
			console.log(data);
		}

		this.socket.send(JSON.stringify(data));
	},
	addCallback(method, callback) {
		if (method === 'onreconnect') {
			this.callbacks[method] = callback;
		} else {
			this.callbacks[method].push(callback);
		}
	},
	removeCallback(method, callback) {
		const index = this.callbacks[method].indexOf(callback);

		if (index !== -1) {
			this.callbacks[method].splice(index, 1);
		}
	}
}

export default Socket
