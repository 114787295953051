import { useRef, useState } from 'react';
import { ReactComponent as Back } from '../images/back.svg'
import useWindowDimensions from '../hooks/window';
import Auth from '../services/auth';
import User from '../services/user';

const Register = ({ setHasUser, setView }) => {
	const [user, setUser] = useState({username: '', password: '', legacy_name: ''});
	const usernameElement = useRef();
	const legacyElement = useRef();
	const dimensions = useWindowDimensions();

	const handleSubmit = async event => {
		event.preventDefault();

		try {
			const { data } = await User.save(user);

			localStorage.setItem('useAudio', localStorage.getItem('useAudio') || 1);
			localStorage.setItem('audioVolume', localStorage.getItem('audioVolume') || 50);

			Auth.loginWithToken(data.jwt);

			setHasUser(1);
		} catch (e) {
			if (e.response && e.response.status === 400) {
				const errors = e.response.data;

				if (errors.username) {
					usernameElement.current.setCustomValidity(errors.username);
					usernameElement.current.reportValidity();
				} else if (errors.legacy_name) {
					legacyElement.current.setCustomValidity(errors.legacy_name);
					legacyElement.current.reportValidity();
				}
			}
		}
	};
	
	const handleUpdate = (field, value) => {
		const {...update} = user;

		update[field] = value;
		usernameElement.current.setCustomValidity('');
		legacyElement.current.setCustomValidity('');

		setUser(update);
	};

	return (
		<>
			<div className="user-title">Register</div>
			<form method="post" onSubmit={event => handleSubmit(event)} autoComplete="off">
				<div className="user-field">
					<label htmlFor="username">Username</label>
					<input type="text" id="username" name="username" maxLength="32" value={user.username} onInput={({ target }) => handleUpdate('username', target.value)} ref={usernameElement} />
				</div>
				<div className="user-field">
					<label htmlFor="password">Password</label>
					<input type="password" id="password" name="password" value={user.password} onInput={({ target }) => handleUpdate('password', target.value)} />
				</div>
				<div className="user-field">
					<label htmlFor="legacy_name">Legacy Name</label>
					<input type="text" id="legacy_name" name="legacy_name" maxLength="32" value={user.legacy_name} onInput={({ target }) => handleUpdate('legacy_name', target.value)} ref={legacyElement} />
				</div>
				<div className="user-field actions">
					<button className="button" type="submit" disabled={!user.username || !user.password || !user.legacy_name}>Register</button>
				</div>
				{dimensions.size !== 'narrow' && <div id="user-create-back" onClick={() => setView('login')}>
					<Back />
				</div>}
			</form>
		</>
	);
}

export default Register;