const Alert = ({ alert, setAlert, value = false }) => {
	return (
		<>
			<div id="modal-outer" onClick={() => setAlert(value)}></div>
			<div id="character-modal-small" className="alert">
				<div className="character-modal-small-header"><p>{alert}</p></div>
				<div className="character-modal-small-actions">
					<div className="character-modal-small-action">
						<div className="character-action" onClick={() => setAlert(value)}>Close</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Alert;