export default function useSwipe(callbacks = {}) {
    let touchStart = null;
    let touchEnd = null;

    const minSwipeDistance = 50;

    const onTouchStart = event => {
        if (event.target.nodeName === 'INPUT' && event.target.type === 'range') {
            return;
        }
        
        touchEnd = null;
        touchStart = {x: event.targetTouches[0].clientX, y: event.targetTouches[0].clientY};
    }

    const onTouchMove = event => {
        touchEnd = {x: event.targetTouches[0].clientX, y: event.targetTouches[0].clientY};
    }

    const onTouchEnd = event => {
        if (! touchStart || ! touchEnd) {
            return;
        }

        const distanceX = touchStart.x - touchEnd.x;
        const distanceY = touchStart.y - touchEnd.y;
        const isLeftSwipe = distanceX > minSwipeDistance;
        const isRightSwipe = distanceX < -minSwipeDistance;

        if (Math.abs(distanceX) > Math.abs(distanceY)) {
            if (isLeftSwipe && callbacks.left) {
                callbacks.left.call(null, event);
            } else if (isRightSwipe && callbacks.right) {
                callbacks.right.call(null, event);
            }
        }
    }

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd
    }
}