import { lazy, Suspense, useState } from 'react';
import './App.css';
import Alert from './components/alert';
import Start from './components/start';
import Auth from './services/auth';
const Main = lazy(() => import('./components/main'));

const App = () => {
    const [hasUser, setHasUser] = useState(Auth.getUser() ? 1 : 0);

    return (
        <div className="App">
            {hasUser !== 1 && <Start setHasUser={setHasUser} />}
            {hasUser === 1 && <Suspense fallback={<div>Loading...</div>}>
                <Main setHasUser={setHasUser} />
            </Suspense>}
            {hasUser === -1 && <Alert alert="You have been logged out due to inactivity" setAlert={setHasUser} value="0" />}
        </div>
    );
}

export default App;
