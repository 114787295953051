import { useRef, useState } from 'react';
import Auth from '../services/auth';

const Login = ({ setHasUser, setView }) => {
	const [user, setUser] = useState({username: '', password: ''});
	const usernameElement = useRef();

	const handleSubmit = async event => {
		event.preventDefault();

		try {
			await Auth.login(user);

			if (localStorage.getItem('useAudio') === null) {
				localStorage.setItem('useAudio', 1);
				localStorage.setItem('audioVolume', 50);
			}

			setHasUser(1);
		} catch (e) {
			if (e.response && e.response.status === 400) {
				usernameElement.current.setCustomValidity(e.response.data);
				usernameElement.current.reportValidity();
			}
		}
	};
	
	const handleUpdate = (field, value) => {
		const {...update} = user;

		update[field] = value;
		usernameElement.current.setCustomValidity('');

		setUser(update);
	};

	return (
		<>
			<div className="user-title">Login</div>
			<form method="post" className="login" onSubmit={event => handleSubmit(event)} autoComplete="off">
				<div className="user-field">
					<label htmlFor="username">Username</label>
					<input type="text" id="username" name="username" value={user.username} onInput={({ target }) => handleUpdate('username', target.value)} ref={usernameElement} />
				</div>
				<div className="user-field">
					<label htmlFor="password">Password</label>
					<input type="password" id="password" name="password" value={user.password} onInput={({ target }) => handleUpdate('password', target.value)} />
				</div>
				<div className="user-field actions">
					<button className="button" type="submit" disabled={!user.username || !user.password}>Login</button>
					<button className="button" type="button" onClick={() => setView('register')}>Register</button>
				</div>
			</form>
		</>
	);
}

export default Login;